
function hasBodyClass(className) {
  return document.body.classList.contains(className)
}

/**
 * Emit a custom event
 * @param  {String} type   The event type
 * @param  {Object} detail Any details to pass along with the event
 * @param  {Node}   elem   The element to attach the event to
 */
function emitEvent(type, detail = {}, elem = document) {
  if (!type) return

  const event = new CustomEvent(type, {
    bubbles: true,
    cancelable: true,
    detail: detail
  })

  return elem.dispatchEvent(event)
}

function randomNumber(min = 0, max = 1000) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function fetchConfig(type = 'json') {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: `application/${type}` }
  }
}

function debounce(fn, wait) {
  let t
  return (...args) => {
    clearTimeout(t)
    t = setTimeout(() => fn.apply(this, args), wait)
  }
}

function truncateLongTitle(input) {
  return input.length > 5 ? `${input.substring(0, 18)}...` : input
}

async function fetchHTML(endpoint) {
  return await fetch(endpoint)
    .then((response) => response.text())
    .then((responseText) => {
      return new DOMParser().parseFromString(responseText, 'text/html')
    })
}

function lockScroll(lock = false) {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth
  // const pseudoScrollbar = document.querySelector('#pseudo-scrollbar')

  if (lock === true) {
    // if (!pseudoScrollbar) createScrollbar(scrollbarWidth)

    document.documentElement.style.height = '100dvh'
    document.documentElement.style.width = `${document.body.clientWidth}px`
    document.documentElement.style.overflow = 'hidden'
    document.documentElement.style.marginRight = `${scrollbarWidth}px`
    // document.body.style.transform = "translateX(0)";
    document.documentElement.style.setProperty('--cw', `${document.body.clientWidth}px`)

    return
  }

  // if (pseudoScrollbar) pseudoScrollbar.remove()

  document.documentElement.style.height = null
  document.documentElement.style.width = null
  document.documentElement.style.overflow = null
  document.documentElement.style.marginRight = null
  // document.body.style.transform = null;
  // document.body.style.setProperty('--cw', `${document.body.clientWidth}px`)
}

const getViewport = function () {
  let e = window; let a = 'inner'

  if (!('innerWidth' in window)) {
    a = 'client'
    e = document.documentElement || document.body
  }

  return { width: e[a + 'Width'], height: e[a + 'Height'] }
}

const keyEscape = (event) => {
  if (event.code.toLowerCase() !== 'escape') return

  const detailsEl = event.target.closest('details')

  if (!detailsEl) return

  const summaryEl = detailsEl.querySelector('summary')

  try {
    detailsEl.parentElement.toggleDrawer(false)
  } catch (err) {
    detailsEl.removeAttribute('open')
  }

  summaryEl.setAttribute('aria-expanded', false)
  summaryEl.focus()
}

const trapFocusHandlers = {}

function removeTrapFocus(elementToFocus = null) {
  document.removeEventListener('focusin', trapFocusHandlers.focusin)
  document.removeEventListener('focusout', trapFocusHandlers.focusout)
  document.removeEventListener('keydown', trapFocusHandlers.keydown)

  if (elementToFocus) elementToFocus.focus()
}

function onKeyUpEscape(event) {
  if (event.code.toUpperCase() !== 'ESCAPE') return

  const openDetailsElement = event.target.closest('details[open]')
  if (!openDetailsElement) return

  const summaryElement = openDetailsElement.querySelector('summary')
  openDetailsElement.removeAttribute('open')
  summaryElement.setAttribute('aria-expanded', false)
  summaryElement.focus()
}

function trapFocus(container, elementToFocus = container) {
  var elements = getFocusableElements(container)
  var first = elements[0]
  var last = elements[elements.length - 1]

  removeTrapFocus()

  trapFocusHandlers.focusin = (event) => {
    if (event.target !== container && event.target !== last && event.target !== first) return

    document.addEventListener('keydown', trapFocusHandlers.keydown)
  }

  trapFocusHandlers.focusout = function () {
    document.removeEventListener('keydown', trapFocusHandlers.keydown)
  }

  trapFocusHandlers.keydown = function (event) {
    if (event.code.toUpperCase() !== 'TAB') return // If not TAB key
    // On the last focusable element and tab forward, focus the first element.
    if (event.target === last && !event.shiftKey) {
      event.preventDefault()
      first.focus()
    }

    //  On the first focusable element and tab backward, focus the last element.
    if ((event.target === container || event.target === first) && event.shiftKey) {
      event.preventDefault()
      last.focus()
    }
  }

  document.addEventListener('focusout', trapFocusHandlers.focusout)
  document.addEventListener('focusin', trapFocusHandlers.focusin)

  elementToFocus.focus()

  if (
    elementToFocus.tagName === 'INPUT' &&
    ['search', 'text', 'email', 'url'].includes(elementToFocus.type) &&
    elementToFocus.value
  ) {
    elementToFocus.setSelectionRange(0, elementToFocus.value.length)
  }
}

function getFocusableElements(container) {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe"
    )
  )
}

Array.prototype.remove = function (val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] === val) {
      this.splice(i, 1)
      i--
    }
  }
  return this
}

export {
  hasBodyClass,
  emitEvent,
  randomNumber,
  debounce,
  truncateLongTitle,
  fetchHTML,
  keyEscape,
  getViewport,
  lockScroll,
  onKeyUpEscape,
  trapFocus,
  getFocusableElements,
  removeTrapFocus,
  fetchConfig
}
